import { DateTime } from "luxon";
let today = DateTime.local().startOf("day");

export const CLAIM_EVIDENCE_FILE_TYPES = [
  {
    id: "till_receipt",
    name: "Till Receipt",
  },
  {
    id: "vehicle_image",
    name: "Vehicle Image",
  },
  {
    id: "notice",
    name: "Notice",
  },
  {
    id: "additional_information",
    name: "Additional Information",
  },
  {
    id: "dvla_response",
    name: "DVLA Response to Enquiry",
  },
  {
    id: "signature_image",
    name: "Signature",
  },
  {
    id: "customer_image",
    name: "Image of Customer",
  },
  {
    id: "transfer_of_liability",
    name: "Transfer of Liability",
  },
];

function getLastQuarter() {
  let last_quarter = today.quarter - 1;
  if (last_quarter === 0) last_quarter = 1;

  let start_date, end_date;
  if (last_quarter === 1) {
    start_date = DateTime.local()
      .set({ month: 1 })
      .startOf("month")
      .toFormat("yyyy-MM-dd");
    end_date = DateTime.local()
      .set({ month: 3 })
      .endOf("month")
      .toFormat("yyyy-MM-dd");
  }
  if (last_quarter === 2) {
    start_date = DateTime.local()
      .set({ month: 4 })
      .startOf("month")
      .toFormat("yyyy-MM-dd");
    end_date = DateTime.local()
      .set({ month: 6 })
      .endOf("month")
      .toFormat("yyyy-MM-dd");
  }
  if (last_quarter === 3) {
    start_date = DateTime.local()
      .set({ month: 7 })
      .startOf("month")
      .toFormat("yyyy-MM-dd");
    end_date = DateTime.local()
      .set({ month: 9 })
      .endOf("month")
      .toFormat("yyyy-MM-dd");
  }
  if (last_quarter === 4) {
    start_date = DateTime.local()
      .set({ month: 10 })
      .startOf("month")
      .toFormat("yyyy-MM-dd");
    end_date = DateTime.local()
      .set({ month: 12 })
      .endOf("month")
      .toFormat("yyyy-MM-dd");
  }

  return { start_date, end_date };
}

export const DATES = {
  this_week: {
    start_date: today.startOf("week").startOf("day").toFormat("yyyy-MM-dd"),
    end_date: today.endOf("week").endOf("day").toFormat("yyyy-MM-dd"),
  },
  this_month: {
    start_date: today.startOf("month").toFormat("yyyy-MM-dd"),
    end_date: today.endOf("month").toFormat("yyyy-MM-dd"),
  },
  last_month: {
    start_date: today
      .minus({ month: 1 })
      .startOf("month")
      .startOf("day")
      .toFormat("yyyy-MM-dd"),
    end_date: today
      .minus({ month: 1 })
      .endOf("month")
      .endOf("day")
      .toFormat("yyyy-MM-dd"),
  },
  this_quarter: {
    start_date: today.startOf("quarter").startOf("day").toFormat("yyyy-MM-dd"),
    end_date: today.endOf("quarter").endOf("day").toFormat("yyyy-MM-dd"),
  },
  last_quarter: {
    start_date: getLastQuarter().start_date,
    end_date: getLastQuarter().end_date,
  },
  this_year: {
    start_date: today.startOf("year").startOf("day").toFormat("yyyy-MM-dd"),
    end_date: today.endOf("year").endOf("day").toFormat("yyyy-MM-dd"),
  },
  last_year: {
    start_date: today
      .minus({ year: 1 })
      .startOf("year")
      .startOf("day")
      .toFormat("yyyy-MM-dd"),
    end_date: today
      .minus({ year: 1 })
      .endOf("year")
      .endOf("day")
      .toFormat("yyyy-MM-dd"),
  },
};

export const CAR_MAKES = [
  { 
    value: "ABARTH", 
    label: "Abarth", 
    models: [
      { value: "500", label: "500" },
      { value: "595", label: "595" },
      { value: "124_SPIDER", label: "124 Spider" }
    ] 
  },
  { value: "AC", label: "AC" },
  { value: "AK", label: "AK" },
  { value: "ALFA ROMEO", label: "Alfa Romeo" },
  { value: "ALPINE", label: "Alpine" },
  { value: "ALVIS", label: "Alvis" },
  { value: "ARIEL", label: "Ariel" },
  { value: "ASTON MARTIN", label: "Aston Martin" },
  { value: "AUBURN", label: "Auburn" },
  { 
    value: "AUDI", 
    label: "Audi", 
    models: [
      { value: "A3", label: "A3" },
      { value: "A4", label: "A4" },
      { value: "Q5", label: "Q5" },
      { value: "Q7", label: "Q7" },
      { value: "TT", label: "TT" }
    ] 
  },
  { value: "AUSTIN", label: "Austin" },
  { value: "BAC", label: "BAC" },
  { value: "BEAUFORD", label: "Beauford" },
  { value: "BENTLEY", label: "Bentley" },
  { 
    value: "BMW", 
    label: "BMW", 
    models: [
      { value: "1502", label: "1502" },
      { value: "1 SERIES", label: "1 Series" },
      { value: "2 SERIES", label: "2 Series" },
      { value: "3 SERIES", label: "3 Series" },
      { value: "4 SERIES", label: "4 Series" },
      { value: "5 SERIES", label: "5 Series" },
      { value: "6 SERIES", label: "6 Series" },
      { value: "7 SERIES", label: "7 Series" },
      { value: "8 SERIES", label: "8 Series" },
      { value: "Alpina", label: "Alpina" },
      { value: "E9", label: "E9" },
      { value: "i3", label: "i3" },
      { value: "i4", label: "i4" },
      { value: "i5", label: "i5" },
      { value: "i7", label: "i7" },
      { value: "i8", label: "i8" },
      { value: "iX", label: "iX" },
      { value: "M2", label: "M2" },
      { value: "M3", label: "M3" },
      { value: "M4", label: "M4" },
      { value: "M5", label: "M5" },
      { value: "M6", label: "M6" },
      { value: "M8", label: "M8" },
      { value: "X1", label: "X1" },
      { value: "X2", label: "X2" },
      { value: "X3", label: "X3" },
      { value: "X4", label: "X4" },
      { value: "X5", label: "X5" },
      { value: "X6", label: "X6" },
      { value: "X7", label: "X7" },
      { value: "XM", label: "XM" },
      { value: "Z1", label: "Z1" },
      { value: "Z3", label: "Z3" },
      { value: "Z4", label: "Z4" },
      { value: "Z8", label: "Z8" },
    ] 
  },
  { value: "BOWLER", label: "Bowler" },
  { value: "BRAMWITH", label: "Bramwith" },
  { value: "BUGATTI", label: "Bugatti" },
  { value: "BUICK", label: "Buick" },
  { value: "BYD", label: "BYD" },
  { value: "CADILLAC", label: "Cadillac" },
  { value: "CATERHAM", label: "Caterham" },
  { value: "CHESIL", label: "Chesil" },
  { value: "CHEVROLET", label: "Chevrolet" },
  { value: "CHRYSLER", label: "Chrysler" },
  { value: "CITROEN", label: "Citroen" },
  { value: "CORBIN", label: "Corbin" },
  { value: "CORVETTE", label: "Corvette" },
  { value: "CUPRA", label: "CUPRA" },
  { value: "DACIA", label: "Dacia" },
  { value: "DAEWOO", label: "Daewoo" },
  { value: "DAIHATSU", label: "Daihatsu" },
  { value: "DAIMLER", label: "Daimler" },
  { value: "DATSUN", label: "Datsun" },
  { value: "DAX", label: "Dax" },
  { value: "DFSK", label: "DFSK" },
  { value: "DODGE", label: "Dodge" },
  { value: "DS AUTOMOBILES", label: "DS Automobiles" },
  { value: "E-COBRA", label: "E-Cobra" },
  { value: "FERRARI", label: "Ferrari" },
  { value: "FIAT", label: "Fiat" },
  { value: "FISKER", label: "Fisker" },
  { 
    value: "FORD", 
    label: "Ford", 
    models: [
      { value: "TRANSIT", label: "TRANSIT" },
    ] 
  },
  { value: "GARDNER DOUGLAS", label: "Gardner Douglas" },
  { value: "GENESIS", label: "Genesis" },
  { value: "GMC", label: "GMC" },
  { value: "GREAT WALL", label: "Great Wall" },
  { value: "GWM ORA", label: "GWM ORA" },
  { value: "HOLDEN", label: "Holden" },
  { value: "HONDA", label: "Honda" },
  { value: "HUMMER", label: "Hummer" },
  { value: "HYUNDAI", label: "Hyundai" },
  { value: "INEOS", label: "INEOS" },
  { value: "INFINITI", label: "Infiniti" },
  { value: "ISUZU", label: "Isuzu" },
  { value: "IVECO", label: "Iveco" },
  { value: "JAGUAR", label: "Jaguar" },
  { value: "JEEP", label: "Jeep" },
  { value: "JENSEN", label: "Jensen" },
  { value: "KGM", label: "KGM" },
  { 
    value: "KIA", 
    label: "Kia", 
    models: [
      { value: "NIRO 2 HEV S-A", label: "NIRO 2 HEV S-A" },
    ] 
  },
  { value: "KOENIGSEGG", label: "Koenigsegg" },
  { value: "KTM", label: "KTM" },
  { value: "LADA", label: "Lada" },
  { value: "LAMBORGHINI", label: "Lamborghini" },
  { value: "LANCIA", label: "Lancia" },
  { 
    value: "LAND ROVER", 
    label: "Land Rover", 
    models: [
      { value: "Defender", label: "Defender" },
      { value: "Discovery", label: "Discovery" },
      { value: "Freelander", label: "Freelander" },
      { value: "Lightweight", label: "Lightweight" },
      { value: "Range Rover", label: "Range Rover" },
      { value: "Series I", label: "Series I" },
      { value: "Series II", label: "Series II" },
      { value: "Series III", label: "Series III" },
    ] 
  },
  { value: "LEVC", label: "LEVC" },
  { value: "LEXUS", label: "Lexus" },
  { value: "LEYLAND", label: "Leyland" },
  { value: "LINCOLN", label: "Lincoln" },
  { value: "LISTER", label: "Lister" },
  { value: "LONDON TAXIS INT", label: "London Taxis International" },
  { value: "LOTUS", label: "Lotus" },
  { value: "MAHINDRA", label: "Mahindra" },
  { value: "MARCOS", label: "Marcos" },
  { value: "MASERATI", label: "Maserati" },
  { value: "MAXUS", label: "MAXUS" },
  { value: "MAYBACH", label: "Maybach" },
  { value: "MAZDA", label: "Mazda" },
  { value: "MCLAREN", label: "McLaren" },
  { 
    value: "MERCEDES-BENZ", 
    label: "Mercedes-Benz", 
    models: [
      { value: "A", label: "A" },
      { value: "B", label: "B" },
      { value: "C", label: "C" },
      { value: "E", label: "E" },
      { value: "G", label: "G" },
      { value: "M", label: "M" },
      { value: "S", label: "S" },
      { value: "V", label: "V" },
      { value: "X", label: "X" },
    ] 
  },
  { value: "MG", label: "MG" },
  { value: "MICRO", label: "Micro" },
  { value: "MINI", label: "MINI" },
  { value: "MITSUBISHI", label: "Mitsubishi" },
  { value: "MITSUOKA", label: "Mitsuoka" },
  { value: "MOKE", label: "Moke" },
  { value: "MORGAN", label: "Morgan" },
  { value: "MORRIS", label: "Morris" },
  { value: "NARDINI", label: "Nardini" },
  { value: "NG", label: "NG" },
  { value: "NISSAN", label: "Nissan" },
  { value: "NOBLE", label: "Noble" },
  { value: "OLDSMOBILE", label: "Oldsmobile" },
  { value: "OMODA", label: "Omoda" },
  { value: "OPEL", label: "Opel" },
  { value: "PAGANI", label: "Pagani" },
  { value: "PERODUA", label: "Perodua" },
  { 
    value: "PEUGEOT", 
    label: "Peugeot", 
    models: [
      { value: "207", label: "207" },
    ] 
  },
  { value: "PGO", label: "PGO" },
  { value: "PILGRIM", label: "Pilgrim" },
  { value: "PLYMOUTH", label: "Plymouth" },
  { value: "POLESTAR", label: "Polestar" },
  { value: "PONTIAC", label: "Pontiac" },
  { 
    value: "PORSCHE", 
    label: "Porsche", 
    models: [
      { value: "E", label: "E" },
      { value: "911", label: "911" },
      { value: "CAYMAN", label: "Cayman" },
      { value: "MACAN", label: "Macan" },
      { value: "PANAMERA", label: "Panamera" },
      { value: "TAYCAN", label: "Taycan" },

    ] 
  },
  { value: "PROTON", label: "Proton" },
  { value: "RADICAL", label: "Radical" },
  { value: "RAGE", label: "Rage" },
  { value: "RELIANT", label: "Reliant" },
  { value: "RENAULT", label: "Renault" },
  { value: "RILEY", label: "Riley" },
  { value: "ROBIN HOOD", label: "Robin Hood" },
  { value: "ROLLS-ROYCE", label: "Rolls-Royce" },
  { value: "ROVER", label: "Rover" },
  { value: "SAAB", label: "Saab" },
  { value: "SANTANA", label: "Santana" },
  { value: "SATURN", label: "Saturn" },
  { value: "SEAT", label: "SEAT" },
  { value: "SEBRING", label: "Sebring" },
  { value: "SHELBY", label: "Shelby" },
  { value: "SINGER", label: "Singer" },
  { value: "SKODA", label: "Skoda" },
  { value: "SMART", label: "Smart" },
  { value: "SSANGYONG", label: "SsangYong" },
  { value: "STANDARD", label: "Standard" },
  { value: "SUBARU", label: "Subaru" },
  { value: "SUNBEAM", label: "Sunbeam" },
  { value: "SUZUKI", label: "Suzuki" },
  { value: "TATA", label: "Tata" },
  { value: "TESLA", label: "Tesla" },
  { value: "TIGER", label: "Tiger" },
  { 
    value: "TOYOTA", 
    label: "Toyota", 
    models: [
      { value: "AURIS", label: "Auris" },
      { value: "AVENSIS", label: "Avensis" },
      { value: "AYGO", label: "AYGO" },
      { value: "CAMRY", label: "Camry" },
      { value: "COROLLA", label: "Corolla" },
      { value: "LAND CRUISER", label: "Land Cruiser" },
      { value: "PRIUS", label: "Prius" },
      { value: "YARIS", label: "Yaris" },
    ] 
  },
  { value: "TRIUMPH", label: "Triumph" },
  { value: "TVR", label: "TVR" },
  { value: "ULTIMA", label: "Ultima" },
  { value: "VAUXHALL", label: "Vauxhall" },
  { 
    value: "VOLKSWAGEN", 
    label: "Volkswagen", 
    models: [
      { value: "CRAFTER", label: "CRAFTER" },
    ] 
  },
  { value: "VOLVO", label: "Volvo" },
  { value: "WESTFIELD", label: "Westfield" },
  { value: "YAMAHA", label: "Yamaha" },
  { value: "ZENOS", label: "Zenos" },
];

export const COLOURS = [
  { value: "Black", label: "Black", },
  { value: "White", label: "White", },
  { value: "Grey", label: "Grey", },
  { value: "Blue", label: "Blue", },
  { value: "Red", label: "Red" },
  { value: "Silver", label: "Silver" },
  { value: "Orange", label: "Orange" },
  { value: "Green", label: "Green" },
  { value: "Bronze", label: "Bronze" },
  { value: "Yellow", label: "Yellow" },
];
